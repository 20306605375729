<template>
  <v-select
    v-model="cdPrazo"
    :items="listaPrazoPagto"
    item-text="nmPrazo"
    item-value="cdPrazo"
    label="Prazo"
    outlined
    hide-details
    :dense="dense"
    @change="onSelectPrazo"
  />
</template>
<script>
  import prazoPagtoRep from './../../components/services/prazoPagtoRepository'

  export default {
    props: {
      dense: {
        type: Boolean,
        default: false,
      },
      id: {
        type: Number,
        default: 0,
      },
    },
    data () {
      return {
        listaPrazoPagto: [],
        cdPrazo: 0,
      }
    },
    async created () {
      this.getList()
      // setTimeout(() => {
      //   this.getList()
      // }, 800)
      this.cdPrazo = this.id
    },
    methods: {
      async getList () {
        try {
          this.$store.commit('setSplashScreen', true)
          await prazoPagtoRep.getList().then(response => {
            if (response.data.status === 'sucesso') {
              this.$store.commit('setSplashScreen', false)
              this.listaPrazoPagto = response.data.dados
            } else if (response.data.status === 'mensagem') {
              this.$swal({
                icon: 'warning',
                text: response.data.dados,
              })
            } else if (response.data.status === 'error') {
              this.$swal({
                icon: 'error',
                text: response.data.messagem,
              })
            }
          }).catch(error => {
            this.$swal({
              icon: 'error',
              text: error,
            })
          })
        } catch (error) {

        }
      },
      onSelectPrazo () {
        var data = this.listaPrazoPagto.filter((item) => {
          if (item.cdPrazo === this.cdPrazo) { return item }
        })
        this.$emit('onSelectPrazo', data[0])
      },
    },
  }
</script>
