<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-card>
      <v-card-title>
        Lista de Cliente
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Informe o nome do Cliente"
          single-line
          hide-details
          @keydown.enter="getLista"
          @click:append="getLista"
        />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="listaCliente"
        @click:row="onClickSelect($event)"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="onClickSelect(item)"
          >
            mdi-check
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import participanteRep from './../../components/services/clienteRepository'

  export default {
    data () {
      return {
        search: '',
        listaCliente: [],
        cliente: {
          cdCpfCgc: '',
          nmCliente: '',
          cdCpfCgcFormatado: '',
        },
        headers: [
          {
            text: 'Nome',
            align: 'start',
            value: 'cliente',
          },
          { text: '', value: 'actions', sortable: false },
        ],
      }
    },
    // created () {
    //   this.getLista()
    // },
    methods: {
      onClickSelect (value) {
        this.$emit('onSelectCliente', value)
      },
      getLista () {
        if (this.validarPesquisa()) {
          this.$store.commit('setSplashScreen', true)
          participanteRep.getList(this.search).then(response => {
            // listaUsuarios = response.data
            if (response.data.status === 'sucesso') {
              this.$store.commit('setSplashScreen', false)
              this.listaCliente = response.data.dados
            }
          }).catch(() => {
            this.$store.commit('setSplashScreen', false)
          })
        }
      },
      validarPesquisa () {
        if (typeof (this.search) === 'undefined') {
          this.$swal({
            icon: 'warning',
            text: 'Informe o nome do Cliente para consulta.',
          })
          return false
        } else if (this.search === '') {
          this.$swal({
            icon: 'warning',
            text: 'Informe o nome do Cliente para consulta.',
          })
          return false
        }
        return true
      },
    },
  }
</script>

<style>

</style>
