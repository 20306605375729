import Repository from './repository'

const resource = '/ajusteprazogrupo'

export default {

  get (prazo, grupo, item) {
    return Repository.get(`${resource}/get/${prazo}/${grupo}/${item}`)
  },
}
