<template>
  <v-container>
    <v-row dense>
      <v-col
        v-if="showruf"
        cols="5"
        md="2"
      >
        <v-select
          v-if="carregaruf"
          v-model="codigouf"
          :items="listaUf"
          label="Uf"
          item-text="nmUf"
          item-value="cdUf"
          hide-details
          dense
          outlined
          @change="onSelectUf"
        />
        <v-progress-circular
          v-else
          indeterminate
          color="primary"
        />
      </v-col>
      <v-col
        v-if="showcidade"
        cols="7"
        md="5"
      >
        <v-select
          v-if="carregarcidade"
          v-model="codigocidade"
          :items="listaCidade"
          label="Cidade"
          item-text="nmCidade"
          item-value="cdCidade"
          hide-details
          dense
          outlined
          @change="onSelectCidade"
        />
        <v-progress-circular
          v-else
          indeterminate
          color="primary"
        />
      </v-col>
      <v-col
        v-if="showbairro"
        cols="12"
        md="5"
      >
        <v-select
          v-if="carregarbairro"
          v-model="codigobairro"
          :items="listaBairro"
          label="Bairro"
          item-text="nmBairro"
          item-value="cdBairro"
          hide-details
          dense
          outlined
          @change="onSelectBairro"
        />
        <v-progress-circular
          v-else
          indeterminate
          color="primary"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import ufRep from './../../../components/services/ufRepository'
  import cidadeRep from './../../../components/services/cidadeRepository'
  import bairroRep from './../../../components/services/bairroRepository'

  export default {
    props: {
      showruf: {
        type: Boolean,
        default: false,
      },
      showcidade: {
        type: Boolean,
        default: false,
      },
      showbairro: {
        type: Boolean,
        default: false,
      },
      uf: {
        type: String,
        default: '',
      },
      cidade: {
        type: Number,
        default: 0,
      },
      nmcidade: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        listaUf: [],
        listaCidade: [],
        listaBairro: [],
        carregaruf: true,
        carregarcidade: true,
        carregarbairro: true,
        codigouf: '',
        codigocidade: 0,
        codigobairro: 0,
      }
    },
    mounted () {
      if (this.showruf === true) this.getListaUf()
    },
    methods: {
      async getListaUf () {
        this.carregaruf = false
        await ufRep.getList().then(response => {
          if (response.data.status === 'sucesso') {
            this.carregaruf = true
            this.listaUf = response.data.dados
            if (this.uf !== '') {
              this.codigouf = this.uf
            }
            if (this.showcidade === true) {
              if (this.uf !== '' && (this.cidade !== 0 || this.nmcidade !== '')) { // FOU REALIZADA UM CONSULTA POR CEP
                this.getListaCidade()
              }
            }
          } else if (response.data.status === 'mensagem') {
            this.$swal({
              icon: 'warning',
              text: response.data.dados,
            })
          } else if (response.data.status === 'error') {
            this.$swal({
              icon: 'error',
              text: response.data.messagem,
            })
          }
        }).catch(error => {
          this.$swal({
            icon: 'error',
            text: error,
          })
        })
      },
      async getListaCidade () {
        this.carregarcidade = false
        await cidadeRep.getList(this.codigouf).then(response => {
          if (response.data.status === 'sucesso') {
            setTimeout(() => {
              this.carregarcidade = true
            }, 1000)
            this.listaCidade = response.data.dados
            if (this.nmcidade !== '') {
              var retorno = this.listaCidade.filter((item) => {
                return item.nmCidade === this.nmcidade
              })
              if (retorno.length > 0) {
                this.cidade = retorno[0].cdCidade
                this.$emit('onSelectCidade', 999)
                this.$emit('onSelectCidade', this.cidade)
              }
            }
            if (this.cidade !== 0) {
              this.$emit('onSelectCidade', this.cidade)
              this.codigocidade = this.cidade
              if (this.showbairro === true) this.getListaBairro()
            }
          } else if (response.data.status === 'mensagem') {
            this.$swal({
              icon: 'warning',
              text: response.data.dados,
            })
          } else if (response.data.status === 'error') {
            this.$swal({
              icon: 'error',
              text: response.data.messagem,
            })
          }
        }).catch(error => {
          this.$swal({
            icon: 'error',
            text: error,
          })
        })
      },
      async getListaBairro () {
        this.carregarbairro = false
        await bairroRep.getList(this.codigouf, this.codigocidade).then(response => {
          if (response.data.status === 'sucesso') {
            this.carregarbairro = true
            this.listaBairro = response.data.dados
          } else if (response.data.status === 'mensagem') {
            this.$swal({
              icon: 'warning',
              text: response.data.dados,
            })
          } else if (response.data.status === 'error') {
            this.$swal({
              icon: 'error',
              text: response.data.messagem,
            })
          }
        }).catch(error => {
          this.$swal({
            icon: 'error',
            text: error,
          })
        })
      },
      onSelectUf () {
        this.$emit('onSelectUf', this.codigouf)
        if (this.showcidade === true) this.getListaCidade()
      },
      onSelectCidade () {
        this.$emit('onSelectCidade', this.codigocidade)
        if (this.showbairro === true) this.getListaBairro()
      },
      onSelectBairro () {
        var data = this.listaBairro.filter((item) => {
          return item.cdBairro === this.codigobairro
        })
        this.$emit('onSelectBairro', data[0])
      },
    },
  }
</script>
