<template>
  <validation-observer
    ref="observer"
    v-slot="{ invalid }"
  >
    <form @submit.prevent="onSalvar()">
      <v-card>
        <v-card>
          <v-toolbar
            dark
            color="primary"
          >
            <v-toolbar-title v-if="editar">
              Editar Caixa *
            </v-toolbar-title>
            <v-toolbar-title v-else>
              Novo Cliente *
            </v-toolbar-title>
            <v-spacer />
            <v-btn
              class="mx-2"
              small
              fab
              color="amber"
              :disabled="invalid"
              @click="onSalvar"
            >
              <v-icon class="white--text">
                mdi-content-save
              </v-icon>
            </v-btn>
            <v-btn
              class="mx-2"
              small
              fab
              color="light-green accent-4"
              @click="onSair"
            >
              <v-icon class="white--text">
                mdi-exit-to-app
              </v-icon>
            </v-btn>
          </v-toolbar>
        </v-card>
        <v-card>
          <v-container>
            <v-row dense>
              <v-col cols="12">
                <v-radio-group
                  v-model="tipoPessoa"
                  row
                  dense
                >
                  <v-radio
                    label="Física"
                    value="P"
                  />
                  <v-radio
                    label="Jurídica"
                    value="J"
                  />
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row
              dense
            >
              <v-col
                cols="9"
                md="3"
              >
                <v-text-field
                  v-if="flshowcpfcnjp"
                  v-model="cliente.cdCpfCgc"
                  v-mask="mascaracpfcnpj"
                  :label="labelPFPJ"
                  :error-messages="cpfcnpjError"
                  outlined
                  dense
                  autofocus
                  required
                  @input="$v.cliente.cdCpfCgc.$touch()"
                  @blur="$v.cliente.cdCpfCgc.$touch()"
                />
              </v-col>
            </v-row>
            <v-row
              dense
              class="pa-0 ma-0"
            >
              <v-col
                cols="4"
                md="2"
              >
                <v-checkbox
                  v-model="cliente.flIsentoCgf"
                  label="Isento"
                  class="pa-0 pt-2 ma-0"
                  dense
                  hide-details
                />
              </v-col>
              <v-col
                cols="4"
                md="2"
              >
                <v-checkbox
                  v-model="cliente.flContribuinte"
                  label="Contribuinte"
                  class="pa-0 pt-2 ma-0"
                  dense
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col
                cols="12"
                md="8"
              >
                <v-text-field
                  v-model="cliente.nmCliente"
                  label="Nome"
                  :error-messages="nmClienteError"
                  outlined
                  dense
                  :counter="60"
                  required
                  @input="$v.cliente.nmCliente.$touch()"
                  @blur="$v.cliente.nmCliente.$touch()"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col
                cols="12"
                md="8"
              >
                <v-text-field
                  v-if="showJuridica"
                  v-model="cliente.nmRazaoSocial"
                  label="Razão Social"
                  outlined
                  dense
                  :counter="60"
                  :class="cssJuridica"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-if="showJuridica"
                  v-model="cliente.nrCgf"
                  label="IE"
                  outlined
                  dense
                  :counter="20"
                  :class="cssJuridica"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col
                cols="5"
                md="2"
              >
                <v-text-field
                  v-if="carregarcep"
                  v-model="cep"
                  v-mask="'#####-###'"
                  :error-messages="cepError"
                  label="Cep"
                  outlined
                  dense
                  :counter="9"
                  @input="$v.cliente.cep.$touch()"
                  @blur="$v.cliente.cep.$touch()"
                />
                <v-progress-circular
                  v-else
                  indeterminate
                  color="primary"
                />
              </v-col>
              <v-col
                cols="7"
                md="10"
              >
                <v-text-field
                  v-if="carregarcep"
                  v-model="cliente.nmEnder"
                  label="Logradouro"
                  :error-messages="nmEnderError"
                  outlined
                  dense
                  :counter="40"
                  @input="$v.cliente.nmEnder.$touch()"
                  @blur="$v.cliente.nmEnder.$touch()"
                />
                <v-progress-circular
                  v-else
                  indeterminate
                  color="primary"
                />
              </v-col>
            </v-row>
            <v-row>
              <componenteufcidadebairro
                v-if="carregarcep"
                showruf
                showcidade
                showbairro
                :uf="uf"
                :cidade="cidade"
                @onSelectUf="onSelectUf"
                @onSelectCidade="onSelectCidade"
                @onSelectBairro="onSelectBairro"
              />
              <v-progress-circular
                v-else
                indeterminate
                color="primary"
              />
            </v-row>
            <v-row dense>
              <v-col
                cols="4"
                md="2"
              >
                <v-text-field
                  v-if="carregarcep"
                  v-model="cliente.nrEnder"
                  label="N°"
                  outlined
                  :error-messages="nrEnderError"
                  dense
                  :counter="6"
                  @input="$v.cliente.nrEnder.$touch()"
                  @blur="$v.cliente.nrEnder.$touch()"
                />
                <v-progress-circular
                  v-else
                  indeterminate
                  color="primary"
                  class="pl-2"
                />
              </v-col>
              <v-col
                cols="8"
                md="8"
              >
                <v-text-field
                  v-if="carregarcep"
                  v-model="cliente.nmComplEnder"
                  :error-messages="nmComplEnderError"
                  label="Complemento"
                  outlined
                  dense
                  :counter="30"
                  @input="$v.cliente.nmComplEnder.$touch()"
                  @blur="$v.cliente.nmComplEnder.$touch()"
                />
                <v-progress-circular
                  v-else
                  indeterminate
                  color="primary"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col
                cols="3"
                md="1"
              >
                <v-text-field
                  v-if="carregarcep"
                  v-model="cliente.nrDDD"
                  v-mask="'(##)'"
                  label="DDD"
                  outlined
                  dense
                  :error-messages="nrDDDError"
                  @input="$v.cliente.nrDDD.$touch()"
                  @blur="$v.cliente.nrDDD.$touch()"
                />
                <v-progress-circular
                  v-else
                  indeterminate
                  color="primary"
                />
              </v-col>
              <v-col
                cols="6"
                md="2"
              >
                <v-text-field
                  v-model="cliente.nrFone1"
                  v-mask="'#####-####'"
                  label="Telefone"
                  outlined
                  dense
                  :counter="10"
                  :error-messages="nrFone1Error"
                  @input="$v.cliente.nrFone1.$touch()"
                  @blur="$v.cliente.nrFone1.$touch()"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="cliente.nmContato"
                  label="Contato"
                  outlined
                  dense
                  :counter="30"
                  :error-messages="nmContatoError"
                  @input="$v.cliente.nmContato.$touch()"
                  @blur="$v.cliente.nmContato.$touch()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="8"
              >
                <v-text-field
                  v-model="cliente.nmEmail"
                  label="Email"
                  outlined
                  dense
                  :counter="50"
                  :error-messages="nmEmailError"
                  @input="$v.cliente.nmEmail.$touch()"
                  @blur="$v.cliente.nmEmail.$touch()"
                />
              </v-col>
            </v-row>
            <!-- <v-card-actions>
              <v-btn
                color="primary"
                :disabled="!invalid"
                @click="onSalvar"
              >
                Salvar
              </v-btn>
            </v-card-actions> -->
          </v-container>
        </v-card>
      </v-card>
    </form>
  </validation-observer>
</template>
<script>
  // import { cpf, cnpj } from 'cpf-cnpj-validator'
  import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
  import { cpf, cnpj } from 'cpf-cnpj-validator'
  // REPOSITORY
  import { GetCep } from '../../../components/services/cepService'
  import cidadeRep from '../../../components/services/cidadeRepository'
  import clienteRep from '../../../components/services/clienteRepository'
  import componenteufcidadebairro from '../../componentes/auxiliares/componete-uf-cidade-bairro'

  // function validarCpfCnpj (value) {
  //   if (typeof (value) !== 'undefined') {
  //     var dadosCpfCNPJ = value.toString().replace(/\D/g, '')
  //     if (dadosCpfCNPJ.length === 11) {
  //       // var cpfValidar = value.toString().replace(/\D/g, '')
  //       if (cpf.isValid(dadosCpfCNPJ)) return true
  //       else return false
  //     } else if (dadosCpfCNPJ === 14) {
  //       // var cnpjValidar = value.toString().replace(/\D/g, '')
  //       if (cnpj.isValid(dadosCpfCNPJ)) return true
  //       else return false
  //     } else {
  //       return false
  //     }
  //   } else return false
  // }
  export default {
    components: {
      // ValidationProvider,
      // ValidationObserver,
      componenteufcidadebairro,
    },
    validations: {
      cliente: {
        cdCpfCgc: {
          required,
        },
        nmCliente: {
          required,
          maxLength: maxLength(60),
        },
        nmEnder: {
          required,
          maxLength: maxLength(40),
        },
        nrEnder: {
          required,
          maxLength: maxLength(6),
        },
        nmComplEnder: {
          maxLength: maxLength(30),
        },
        nrDDD: {
          required,
        },
        nrFone1: {
          required,
          minLength: minLength(9),
          maxLength: maxLength(10),
        },
        nmContato: {
          maxLength: maxLength(30),
        },
        nmEmail: {
          maxLength: maxLength(50),
          required,
          email,
        },
      },
      cep: {
        required,
      },
    },
    props: {
      editar: {
        type: Boolean,
        required: true,
      },
    },
    data () {
      return {
        flshowcpfcnjp: true,
        labelPFPJ: 'CPF',
        totCaracterPFPJ: 11,
        tipoPessoa: 'P',
        mascaracpfcnpj: '###.###.###-##',
        cliente: {
          nmEnder: '',
          nrDDD: '',
          cdVendedor: 999,
          nrFax: 0,
          vlLimCredito: 0.00,
          stCliente: 'S',
          txDadosLivre: 'Incluido via App',
          cdUsuario: 'SOFTFAMILY',
          cdSuframa: '0',
          nmEmail: '',
          flSeq: false,
          nrIdentidade: '0000000000',
          cdSegmentacao: 1,
          cdTipoPfPj: 0,
          cdUf: '',
          cdCidade: 0,
          cdBairro: 0,
          nmRazaoSocial: '',
          nrCgf: '',
          flIsentoCgf: false,
          flContribuinte: false,
          nmComplEnder: '',
          nmContato: '',
        },
        carregarcep: true,
        cep: '',
        uf: '',
        cidade: 0,
        showJuridica: false,
        cssJuridica: 'animate__animated animate__fadeInDown',
      }
    },
    computed: {
      cpfcnpjError () {
        const errors = []
        if (!this.$v.cliente.cdCpfCgc.$dirty) return errors
        !this.$v.cliente.cdCpfCgc.required && errors.push('Obrigatório')
        if (typeof (this.cliente.cdCpfCgc) !== 'undefined') {
          if (typeof (this.cliente.cdCpfCgc.length) !== 'undefined') {
            if (this.tipoPessoa === 'P') {
              if (this.cliente.cdCpfCgc.length === 14) {
                if (!cpf.isValid(this.cliente.cdCpfCgc.replace(/[./-]/g, ''))) {
                  errors.push('Cpf inválido')
                }
              }
            }
            if (this.tipoPessoa === 'J') {
              if (this.cliente.cdCpfCgc.length === 18) {
                if (!cnpj.isValid(this.cliente.cdCpfCgc.replace(/[./-]/g, ''))) {
                  errors.push('Cnpj inválido')
                }
              }
            }
          }
        }
        return errors
      },
      nmClienteError () {
        const errors = []
        if (!this.$v.cliente.nmCliente.$dirty) return errors
        !this.$v.cliente.nmCliente.maxLength && errors.push('Tamanho máximo 60')
        !this.$v.cliente.nmCliente.required && errors.push('Obrigatório')
        return errors
      },
      cepError () {
        const errors = []
        if (!this.$v.cep.$dirty) return errors
        !this.$v.cep.required && errors.push('Obrigatório')
        return errors
      },
      nmEnderError () {
        const errors = []
        if (!this.$v.cliente.nmEnder.$dirty) return errors
        !this.$v.cliente.nmEnder.maxLength && errors.push('Tamanho máximo 40')
        !this.$v.cliente.nmEnder.required && errors.push('Obrigatório')
        return errors
      },
      nrEnderError () {
        const errors = []
        if (!this.$v.cliente.nrEnder.$dirty) return errors
        !this.$v.cliente.nrEnder.maxLength && errors.push('Tamanho máximo 6')
        !this.$v.cliente.nrEnder.required && errors.push('Obrigatório')
        return errors
      },
      nmComplEnderError () {
        const errors = []
        if (!this.$v.cliente.nmComplEnder.$dirty) return errors
        !this.$v.cliente.nmComplEnder.maxLength && errors.push('Tamanho máximo 30')
        return errors
      },
      nrDDDError () {
        const errors = []
        if (!this.$v.cliente.nrDDD.$dirty) return errors
        !this.$v.cliente.nrDDD.required && errors.push('Obrigatório')
        return errors
      },
      nrFone1Error () {
        const errors = []
        if (!this.$v.cliente.nrFone1.$dirty) return errors
        !this.$v.cliente.nrFone1.required && errors.push('Obrigatório')
        !this.$v.cliente.nrFone1.maxLength && errors.push('Tamanho máximo 9')
        !this.$v.cliente.nrFone1.minLength && errors.push('Tamanho minimo 9')
        return errors
      },
      nmContatoError () {
        const errors = []
        if (!this.$v.cliente.nmContato.$dirty) return errors
        !this.$v.cliente.nmContato.maxLength && errors.push('Tamanho máximo 30')
        return errors
      },
      nmEmailError () {
        const errors = []
        if (!this.$v.cliente.nmEmail.$dirty) return errors
        !this.$v.cliente.nmEmail.email && errors.push('Email invalido.')
        !this.$v.cliente.nmEmail.required && errors.push('Obrigatório')
        return errors
      },
    },
    watch: {
      tipoPessoa: function (val) {
        if (val === 'P') {
          this.flshowcpfcnjp = false
          this.mascaracpfcnpj = '###.###.###-##'
          this.labelPFPJ = 'CPF'
          this.totCaracterPFPJ = 11
          this.cliente.cdCpfCgc = ''
          this.cssJuridica = 'animate__animated animate__fadeOutUp'
          setTimeout(() => {
            this.showJuridica = false
            this.flshowcpfcnjp = true
          }, 400)
          this.cliente.cdTipoPfPj = 0
        } else {
          this.flshowcpfcnjp = false
          this.cliente.cdTipoPfPj = 1
          this.mascaracpfcnpj = '##.###.###/####-##'
          this.labelPFPJ = 'CNPJ'
          this.totCaracterPFPJ = 18
          this.cliente.cdCpfCgc = ''
          this.cssJuridica = 'animate__animated animate__fadeInDown'
          setTimeout(() => {
            this.showJuridica = true
            this.flshowcpfcnjp = true
          }, 300)
        }
      },
      cep: function (val) {
        if (
          val &&
          val !== null &&
          val.length === 9
        ) {
          this.buscaCep()
        }
      },
    },
    mounted () {
      this.$store.commit('setSplashScreen', false)
    },
    methods: {
      buscaCep () {
        this.carregarcep = false
        var dados = this
        GetCep(this.cep).then(endereco => {
          dados.cliente.nmEnder = endereco.logradouro
          dados.cliente.cdUf = endereco.estado
          if (endereco.ddd) dados.cliente.nrDDD = endereco.ddd
          dados.uf = endereco.estado
          // this.$forceUpdate()
          if (endereco.ibge && endereco.ibge !== '') {
            dados.onCarregaCidade(endereco.ibge)
          }
          setTimeout(() => {
            this.carregarcep = true
          }, 1000)
        }).catch(() => {
          this.carregarcep = true
        })
      },
      async onSalvar () {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          this.$store.commit('setSplashScreen', true)
          if (this.cliente.cdTipoPfPj === 1 && this.cliente.nmRazaoSocial === '') {
            this.$store.commit('setSplashScreen', false)
            this.$swal({
              icon: 'warning',
              text: 'Selecione a UF',
            })
            return true
          } else if (this.cliente.cdUf === '') {
            this.$store.commit('setSplashScreen', false)
            this.$swal({
              icon: 'warning',
              text: 'Selecione a UF',
            })
            return true
          } else if (this.cliente.cdCidade === 0) {
            this.$store.commit('setSplashScreen', false)
            this.$swal({
              icon: 'warning',
              text: 'Selecione a Cidade',
            })
            return true
          } else if (this.cliente.cdBairro === 0) {
            this.$store.commit('setSplashScreen', false)
            this.$swal({
              icon: 'warning',
              text: 'Selecione o Bairro',
            })
            return true
          }
          this.cliente.cdCpfCgc = this.cliente.cdCpfCgc.replace(/\D/g, '')
          this.cliente.nrDDD = this.cliente.nrDDD && this.cliente.nrDDD !== '' ? parseInt(this.cliente.nrDDD.replace(/\D/g, '')) : 0
          this.cliente.nrFone1 = this.cliente.nrFone1 && this.cliente.nrFone1 !== '' ? parseInt(this.cliente.nrFone1.replace(/\D/g, '')) : 0
          this.cliente.cdCep = this.cep && this.cep !== '' ? parseInt(this.cep.replace(/\D/g, '')) : 0
          await clienteRep.Save(this.cliente).then(response => {
            this.$store.commit('setSplashScreen', false)
            if (response.data.status === 'sucesso') {
              this.$swal({
                icon: 'success',
                text: 'Criado com Sucesso!\nPesquise novamente o Cliente',
              })
              setTimeout(() => {
                this.$emit('onSucessoCadastro')
              }, 2500)
            } else if (response.data.status === 'mensagem') {
              this.cliente.nrDDD = String(this.cliente.nrDDD)
              this.cliente.nrFone1 = String(this.cliente.nrFone1)
              this.cliente.cdCpfCgc = String(this.cliente.cdCpfCgc)
              this.$swal({
                icon: 'warning',
                text: response.data.dados,
              })
            } else if (response.data.status === 'error') {
              this.$swal({
                icon: 'error',
                text: response.data.dados.mensagem,
              })
            }
          }).catch(error => {
            this.cliente.nrDDD = String(this.cliente.nrDDD)
            this.cliente.nrFone1 = String(this.cliente.nrFone1)
            this.cliente.cdCpfCgc = String(this.cliente.cdCpfCgc)
            this.cliente.cep = String(this.cliente.cep)
            this.$store.commit('setSplashScreen', false)
            this.$swal({
              icon: 'error',
              text: error,
            })
          })
        } else {
          requestAnimationFrame(() => {
            this.$refs.observer.reset()
          })
        }
      },
      async onCarregaCidade (ibge) {
        await cidadeRep.getListIbge(ibge).then(response => {
          if (response.data.status === 'sucesso') {
            this.cidade = response.data.dados.cdCidade
          } else if (response.data.status === 'mensagem') {
            this.cidade = 0
          } else if (response.data.status === 'error') {
            this.cidade = 0
          }
        }).catch(error => {
          this.$swal({
            icon: 'error',
            text: error,
          })
        })
      },
      onSelectUf (uf) {
        this.cliente.cdUf = uf
      },
      onSelectCidade (cidade) {
        this.cliente.cdCidade = cidade
      },
      onSelectBairro (bairro) {
        this.cliente.cdBairro = bairro.cdBairro
        this.cliente.nmBairro = bairro.nmBairro
      },
      onSair () {
        this.$emit('onSairCliente')
      },
    },
  }
</script>
