import Repository from './repository'

const resource = '/localentrega'

export default {

  getId (id) {
    return Repository.get(`${resource}/${id}`)
  },
  async getList (valor) {
    return await Repository.get(`${resource}/getlist/${valor}`)
  },
  Update (data) {
    return Repository.post(`${resource}/editar/`, data)
  },
  Delete (data) {
    return Repository.post(`${resource}/excluir/`, data)
  },
  Save (data) {
    return Repository.post(`${resource}`, data)
  },
}
