import Repository from './repository'

const resource = '/prazopagto'

export default {

  getId (id) {
    return Repository.get(`${resource}/${id}`)
  },
  async getList () {
    return await Repository.get(`${resource}/getlist`)
  },
  Update (data) {
    return Repository.post(`${resource}/editar/`, data)
  },
  Delete (data) {
    return Repository.post(`${resource}/excluir/`, data)
  },
  Save (data) {
    return Repository.post(`${resource}/save/`, data)
  },
}
