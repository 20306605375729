import Repository from './repository'

const resource = '/cidade'

export default {

  getId (id) {
    return Repository.get(`${resource}/${id}`)
  },
  getList (uf) {
    return Repository.get(`${resource}/getlista/${uf}`)
  },
  getListIbge (ibge) {
    return Repository.get(`${resource}/getlistaibge/${ibge}`)
  },
  Update (data) {
    return Repository.post(`${resource}/editar/`, data)
  },
  Delete (data) {
    return Repository.post(`${resource}/excluir/`, data)
  },
  Save (data) {
    return Repository.post(`${resource}/save/`, data)
  },
}
