<template>
  <validation-observer
    ref="observer"
    v-slot="{ invalid }"
  >
    <form @submit.prevent="onSalvar()">
      <v-card>
        <v-card>
          <v-toolbar
            dark
            color="primary"
          >
            <v-toolbar-title v-if="editar">
              Alterar Impressora *
            </v-toolbar-title>
            <v-toolbar-title v-else>
              Novo*
            </v-toolbar-title>
            <v-spacer />
            <v-btn
              class="mx-2"
              small
              fab
              color="amber"
              :disabled="invalid"
              @click="onSalvar"
            >
              <v-icon class="white--text">
                mdi-content-save
              </v-icon>
            </v-btn>
            <v-btn
              class="mx-2"
              small
              fab
              color="light-green accent-4"
              @click="onSair"
            >
              <v-icon class="white--text">
                mdi-exit-to-app
              </v-icon>
            </v-btn>
          </v-toolbar>
        </v-card>
        <v-card>
          <v-form
            ref="form"
          >
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="localentregaNovo.nmLocalEntrega"
                    label="Nome Local de Entrega"
                    hide-details
                    dense
                    outlined
                    required
                    :counter="30"
                    :error-messages="nmLocalEntregaError"
                    @input="$v.nmLocalEntregaError.$touch()"
                    @blur="$v.nmLocalEntregaError.$touch()"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col
                  cols="5"
                  md="2"
                >
                  <v-text-field
                    v-if="carregarcep"
                    v-model="cep"
                    v-mask="'#####-###'"
                    label="Cep"
                    outlined
                    dense
                    :counter="9"
                    :error-messages="cepError"
                    @input="$v.cep.$touch()"
                    @blur="$v.cep.$touch()"
                  />
                  <v-progress-circular
                    v-else
                    indeterminate
                    color="primary"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="10"
                >
                  <v-text-field
                    v-if="carregarcep"
                    v-model="localentregaNovo.nmEnder"
                    label="Logradouro"
                    dense
                    outlined
                    required
                    :counter="30"
                    :error-messages="nmEnderError"
                    @input="$v.localentregaNovo.nmEnder.$touch()"
                    @blur="$v.localentregaNovo.nmEnder.$touch()"
                  />
                  <v-progress-circular
                    v-else
                    indeterminate
                    color="primary"
                  />
                </v-col>
                <v-col
                  cols="2"
                >
                  <v-text-field
                    v-if="carregarcep"
                    v-model="localentregaNovo.nrEnder"
                    label="N.°"
                    hide-details
                    dense
                    outlined
                    required
                    :counter="5"
                    :error-messages="nrEnderError"
                    @input="$v.localentregaNovo.nrEnder.$touch()"
                    @blur="$v.localentregaNovo.nrEnder.$touch()"
                  />
                  <v-progress-circular
                    v-else
                    indeterminate
                    color="primary"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-if="carregarcep"
                    v-model="localentregaNovo.nmComplEnder"
                    label="Complemento"
                    hide-details
                    dense
                    outlined
                    required
                    :counter="30"
                    :error-messages="nmComplEnderError"
                    @input="$v.localentregaNovo.nmComplEnder.$touch()"
                    @blur="$v.localentregaNovo.nmComplEnder.$touch()"
                  />
                  <v-progress-circular
                    v-else
                    indeterminate
                    color="primary"
                  />
                </v-col>
              </v-row>
              <v-row>
                <compUfCidadeBairro
                  v-if="carregarcep"
                  :uf="uf"
                  :nmcidade="localentregaNovo.nmCidade"
                  showruf
                  showcidade
                  showbairro
                  @onSelectUf="onSelectUf"
                  @onSelectCidade="onSelectCidade"
                  @onSelectBairro="onSelectBairro"
                />
                <v-progress-circular
                  v-else
                  indeterminate
                  color="primary"
                />
              </v-row>
              <v-row dense>
                <v-col
                  cols="3"
                  md="1"
                >
                  <v-text-field
                    v-if="carregarcep"
                    v-model="localentregaNovo.nrDDD"
                    v-mask="'(##)'"
                    label="DDD"
                    outlined
                    dense
                    :error-messages="nrDDDError"
                    @input="$v.localentregaNovo.nrDDD.$touch()"
                    @blur="$v.localentregaNovo.nrDDD.$touch()"
                  />
                  <v-progress-circular
                    v-else
                    indeterminate
                    color="primary"
                  />
                </v-col>
                <v-col
                  cols="6"
                  md="2"
                >
                  <v-text-field
                    v-if="carregarcep"
                    v-model="localentregaNovo.nrFone"
                    v-mask="'#####-####'"
                    label="Telefone"
                    outlined
                    dense
                    :counter="10"
                    :error-messages="nrFoneError"
                    @input="$v.localentregaNovo.nrFone.$touch()"
                    @blur="$v.localentregaNovo.nrFone.$touch()"
                  />
                  <v-progress-circular
                    v-else
                    indeterminate
                    color="primary"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-if="carregarcep"
                    v-model="localentregaNovo.txPontoRef"
                    label="Observação"
                    hide-details
                    dense
                    outlined
                    :counter="80"
                    :error-messages="txPontoRefError"
                    @input="$v.localentregaNovo.txPontoRef.$touch()"
                    @blur="$v.localentregaNovo.txPontoRef.$touch()"
                  />
                  <v-progress-circular
                    v-else
                    indeterminate
                    color="primary"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-card>
    </form>
  </validation-observer>
</template>
<script>
  import { required, maxLength, minLength } from 'vuelidate/lib/validators'
  import { GetCep } from './../../components/services/cepService'
  import cidadeRep from './../../components/services/cidadeRepository'
  import compUfCidadeBairro from './auxiliares/componete-uf-cidade-bairro'
  import localEntregaRep from './../../components/services/localentregaRepository'
  export default {
    components: {
      compUfCidadeBairro,
    },
    validations: {
      localentregaNovo: {
        nmLocalEntrega: {
          required,
          maxLength: maxLength(30),
        },
        nmEnder: {
          required,
          maxLength: maxLength(40),
        },
        nrEnder: {
          required,
          maxLength: maxLength(5),
        },
        nmComplEnder: {
          maxLength: maxLength(30),
        },
        nrDDD: {
          required,
        },
        nrFone: {
          required,
          minLength: minLength(10),
          maxLength: maxLength(10),
        },
        txPontoRef: {
          maxLength: maxLength(80),
        },
      },
      cep: {
        required,
      },
    },
    props: {
      cdcpfcgc: {
        type: String,
        required: true,
      },
      editar: {
        type: Boolean,
        required: true,
      },
    },
    data () {
      return {
        carregarcep: true,
        uf: '',
        cep: '',
        cidade: 0,
        localentregaNovo: {
          nmEnder: '',
          cdUf: '',
          nrDDD: '',
          nrFone: '',
          nmCidade: '',
          txPontoRef: '',
        },
      }
    },
    computed: {
      nmLocalEntregaError () {
        const errors = []
        if (!this.$v.localentregaNovo.nmLocalEntrega.$dirty) return errors
        !this.$v.localentregaNovo.nmLocalEntrega.required && errors.push('Obrigatório')
        !this.$v.localentregaNovo.nmLocalEntrega.maxLength && errors.push('Tamanho máximo 30')
        return errors
      },
      nrEnderError () {
        const errors = []
        if (!this.$v.localentregaNovo.nrEnder.$dirty) return errors
        !this.$v.localentregaNovo.nrEnder.required && errors.push('Obrigatório')
        !this.$v.localentregaNovo.nrEnder.maxLength && errors.push('Tamanho máximo 5')
        return errors
      },
      nmComplEnderError () {
        const errors = []
        if (!this.$v.localentregaNovo.nmComplEnder.$dirty) return errors
        !this.$v.localentregaNovo.nmComplEnder.maxLength && errors.push('Tamanho máximo 40')
        return errors
      },
      nmEnderError () {
        const errors = []
        if (!this.$v.localentregaNovo.nmEnder.$dirty) return errors
        !this.$v.localentregaNovo.nmEnder.required && errors.push('Obrigatório')
        !this.$v.localentregaNovo.nmEnder.maxLength && errors.push('Tamanho máximo 30')
        return errors
      },
      cepError () {
        const errors = []
        if (!this.$v.cep.$dirty) return errors
        !this.$v.cep.required && errors.push('Obrigatório')
        return errors
      },
      nrDDDError () {
        const errors = []
        if (!this.$v.localentregaNovo.nrDDD.$dirty) return errors
        !this.$v.localentregaNovo.nrDDD.required && errors.push('Obrigatório')
        return errors
      },
      nrFoneError () {
        const errors = []
        if (!this.$v.localentregaNovo.nrFone.$dirty) return errors
        !this.$v.localentregaNovo.nrFone.required && errors.push('Obrigatório')
        !this.$v.localentregaNovo.nrFone.maxLength && errors.push('Tamanho máximo 9')
        !this.$v.localentregaNovo.nrFone.minLength && errors.push('Tamanho minimo 9')
        return errors
      },

      txPontoRefError () {
        const errors = []
        if (!this.$v.localentregaNovo.txPontoRef.$dirty) return errors
        !this.$v.localentregaNovo.txPontoRef.maxLength && errors.push('Tamanho máximo 40')
        return errors
      },
    },
    watch: {
      cep: function (val) {
        if (
          val &&
          val !== null &&
          val.length === 9
        ) {
          this.buscaCep()
        }
      },
    },
    // created () {
    //   this.localentregaNovo = []
    // },
    methods: {
      buscaCep () {
        this.carregarcep = false
        var dados = this
        GetCep(this.cep).then(endereco => {
          dados.localentregaNovo.nmEnder = endereco.logradouro
          dados.localentregaNovo.cdUf = endereco.estado
          dados.localentregaNovo.nmCidade = endereco.localidade
          if (endereco.ddd) dados.localentregaNovo.nrDDD = parseInt(endereco.ddd)
          dados.uf = endereco.estado
          // this.$forceUpdate()
          //   if (endereco.ibge && endereco.ibge !== '') {
          //     dados.onCarregaCidade(endereco.ibge)
          //   }
          setTimeout(() => {
            this.carregarcep = true
          }, 1000)
        }).catch(() => {
          this.carregarcep = true
        })
      },
      async onCarregaCidade (ibge) {
        await cidadeRep.getListIbge(ibge).then(response => {
          if (response.data.status === 'sucesso') {
            // console.log(response.data.dados)
            // this.cidade = response.data.dados.cdCidade
          } else if (response.data.status === 'mensagem') {
            this.cidade = 0
          } else if (response.data.status === 'error') {
            this.cidade = 0
          }
        }).catch(error => {
          this.$swal({
            icon: 'error',
            text: error,
          })
        })
      },
      async onSalvar () {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          this.$store.commit('setSplashScreen', true)
          this.localentregaNovo.cdcpfcgc = this.cdcpfcgc
          this.localentregaNovo.nrDDD = this.localentregaNovo.nrDDD && this.localentregaNovo.nrDDD !== '' ? parseInt(this.localentregaNovo.nrDDD.replace(/\D/g, '')) : 0
          this.localentregaNovo.nrFone = this.localentregaNovo.nrFone && this.localentregaNovo.nrFone !== '' ? parseInt(this.localentregaNovo.nrFone.replace(/\D/g, '')) : 0
          this.localentregaNovo.cdCep = this.cep && this.cep !== '' ? parseInt(this.cep.replace(/\D/g, '')) : 0
          await localEntregaRep.Save(this.localentregaNovo).then(response => {
            this.$emit('onCarregar')
            this.$store.commit('setSplashScreen', false)
          }).catch(error => {
            this.$store.commit('setSplashScreen', false)
            this.$swal({
              icon: 'error',
              text: error,
            })
          })
        }
      },
      onSelectUf (uf) {
        this.localentregaNovo.cdUf = uf
      },
      onSelectCidade (cidade) {
        this.localentregaNovo.cdCidade = cidade
      },
      onSelectBairro (bairro) {
        this.localentregaNovo.cdBairro = bairro.cdBairro
        this.localentregaNovo.nmBairro = bairro.nmBairro
      },
      onSair () {
        this.$emit('onSair')
      },
    },
  }
</script>
