<template>
  <v-select
    v-model="cdPrazo"
    :items="listaPrazoPagto"
    item-text="cdPrefDup"
    item-value="cdPrefDup"
    label="Prefixo Dup."
    outlined
    hide-details
    :readonly="readonly"
    :dense="dense"
    @change="onSelectPrefDup"
  />
</template>
<script>
  import prefixoDupRep from './../../components/services/prefixoDupRepository'

  export default {
    props: {
      dense: {
        type: Boolean,
        default: false,
      },
      id: {
        type: Number,
        default: 0,
      },
      readonly: {
        type: Boolean,
        default: true,
      },
      data: {
        type: Object,
        required: true,
      },
      carregardados: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
        listaPrazoPagto: [],
        cdPrazo: 0,
      }
    },
    async created () {
      if (this.carregardados) this.getList()
      this.cdPrazo = this.id
    },
    methods: {
      async getList () {
        try {
          this.$store.commit('setSplashScreen', true)
          await prefixoDupRep.getList(this.data).then(response => {
            this.$store.commit('setSplashScreen', false)
            if (response.data.status === 'sucesso') {
              this.listaPrazoPagto = response.data.dados
            } else if (response.data.status === 'mensagem') {
              this.$swal({
                icon: 'warning',
                text: response.data.dados,
              })
            } else if (response.data.status === 'error') {
              this.$swal({
                icon: 'error',
                text: response.data.messagem,
              })
            }
          }).catch(error => {
            this.$swal({
              icon: 'error',
              text: error,
            })
          })
        } catch (error) {
          this.$swal({
            icon: 'error',
            text: error,
          })
        }
      },
      onSelectPrefDup () {
        this.$emit('onSelectPrefDup', this.cdPrazo)
      },
    },
  }
</script>
