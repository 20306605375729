<template>
  <v-card
    :key="index"
    class="ml-1 mr-1"
  >
    <v-virtual-scroll
      v-if="aguardarConsulta"
      :bench="benched"
      :items="lista"
      :height="heightListControl"
      :item-height="itemheight"
    >
      <template v-slot:default="{ index, item }">
        <v-row
          dense
          class="ml-1 mr-1 mt-1"
        >
          <v-col
            cols="12"
            md="4"
            class="pb-0"
          >
            <p class="primary--text mb-0">
              {{ item.descricao }}
            </p>
          </v-col>
          <v-col
            cols="6"
            md="2"
            class="pb-0"
          >
            <v-list-item-title>
              <strong class="primary--text pr-1 pl-3">Unid:</strong>
              <span class="black--text">{{ item.cdUnidade }}</span>
            </v-list-item-title>
          </v-col>
          <v-col
            cols="6"
            md="3"
            class="pb-0"
          >
            <strong class="primary--text pr-1 pl-3">Preço:</strong>
            <strong
              v-if="codigopreco == 0"
              class="black--text pl-1"
            >
              R$ {{ formatPrice(item.vlUnitario) }}
            </strong>
            <strong
              v-else-if="codigopreco == 1"
              class="black--text pl-1"
            >
              R$ {{ formatPrice(item.vlUnitario2) }}
            </strong>
            <strong
              v-else-if="codigopreco == 3"
              class="black--text pl-1"
            >
              R$ {{ formatPrice(item.vlUnitario3) }}
            </strong>
            <strong
              v-else
              class="black--text pl-1"
            >
              R$ {{ formatPrice(item.vlUnitario) }}
            </strong>
          </v-col>
          <v-col
            v-if="item.vlPesound != 0 && item.vlPesound != 1"
            cols="12"
            md="3"
            class="pb-0"
          >
            <strong class="primary--text pr-1 pl-3">Preço Unid.:</strong>
            <strong
              v-if="codigopreco == 0"
              class="black--text pl-1"
            >
              R$ {{ formatPrice(item.vlUnitario * item.vlPesound) }}
            </strong>
            <strong
              v-else-if="codigopreco == 1"
              class="black--text pl-1"
            >
              R$ {{ formatPrice(item.vlUnitario2 * item.vlPesound) }}
            </strong>
            <strong
              v-else
              class="black--text pl-1"
            >
              R$ {{ formatPrice(item.vlUnitario * item.vlPesound) }}
            </strong>
          </v-col>
        </v-row>
        <v-row
          dense
          class="ml-1 mr-1 pb-3"
        >
          <v-col
            cols="5"
            md="3"
          >
            <span class="text--primary">Físico:</span>
            <strong class="pl-1">{{ formatPrice(item.fisico) }}</strong>
          </v-col>
          <v-col
            cols="5"
            md="3"
          >
            <span class="text--primary">Contábil:</span>
            <strong class="pl-1">{{ formatPrice(item.estoque) }}</strong>
          </v-col>
          <v-col
            cols="2"
            md="6"
            class="d-flex justify-end"
          >
            <v-btn
              fab
              color="primary"
              small
              @click="onAddItem(item)"
            >
              <v-icon color="white">
                mdi-check
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-virtual-scroll>
    <v-progress-linear
      v-else
      indeterminate
      color="primary"
    />
    <v-dialog
      v-model="dialogAddProduto"
      scrollable
      persistent
      max-width="600"
    >
      <modalAddProduto
        v-if="dialogAddProduto"
        :dados="itemSelecionado"
        :prazo="prazo"
        :codigopreco="codigopreco"
        :tipoorcamento="tipoorcamento"
        :preorcamento="preorcamento"
        @onAddProduto="onAddProduto"
        @onAddSair="onAddSair"
      />
    </v-dialog>

    <v-dialog
      v-model="dialogMensagem"
      max-width="600"
    >
      <v-card>
        <v-alert
          dense
          border="left"
          :type="tipoAlerta"
        >
          ATENÇÃO
        </v-alert>
        <strong class="d-flex justify-center">{{ mensagem }}</strong>
        <div class="d-flex justify-center pt-2">
          <v-btn
            depressed
            color="primary"
            @click="dialogMensagem = !dialogMensagem"
          >
            OK
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
  import produtoRep from './../../components/services/produtoRepository'
  import modalAddProduto from './../componentes/componente-vendaproduto'

  export default {
    components: {
      modalAddProduto,
    },
    props: {
      dense: {
        type: Boolean,
        default: false,
      },
      itemheight: {
        type: Number,
        default: 100,
      },
      tipoconsulta: {
        type: String,
        default: '',
      },
      paramconsulta: {
        type: String,
        default: '',
      },
      tipoorcamento: {
        type: String,
        required: true,
      },
      codigopreco: {
        type: Number,
        required: true,
      },
      prazo: {
        type: Number,
        required: true,
        default: 0,
      },
      loja: {
        type: Number,
        required: true,
      },
      preorcamento: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        mensagem: '',
        benched: 200,
        aguardarConsulta: false,
        lista: [],
        heightListControl: 340,
        // VARIAVEIS
        itemSelecionado: {},
        tipoAlerta: '',
        // ## DIALOG ##
        dialogAddProduto: false,
        dialogMensagem: false,
      }
    },
    mounted () {
      this.getPesquisa()
    },
    methods: {
      async getPesquisa () {
        this.aguardarConsulta = false
        if (this.paramconsulta !== '') {
          if (this.tipoconsulta === 'C') {
            await produtoRep.getCode(this.loja, this.paramconsulta).then(response => {
              if (response.data.status === 'sucesso') {
                setTimeout(() => {
                  this.aguardarConsulta = true
                  this.lista.push(response.data.dados)
                  this.heightListControl = 270
                }, 500)
              } else if (response.data.status === 'mensagem') {
                this.aguardarConsulta = false
                this.$swal({
                  icon: 'warning',
                  text: response.data.messagem,
                })
              } else if (response.data.status === 'error') {
                this.$swal({
                  icon: 'error',
                  text: response.data.messagem,
                })
              }
            }).catch(error => {
              this.aguardarConsulta = true
              this.heightListControl = 0
              this.$swal({
                icon: 'error',
                text: 'Produto não Existe ou Código digitado incorretamente! \n' + error,
              })
            })
          } else if (this.tipoconsulta === 'D') {
            await produtoRep.getDesc(this.loja, this.paramconsulta).then(response => {
              if (response.data.status === 'sucesso') {
                setTimeout(() => {
                  this.aguardarConsulta = true
                  this.lista = response.data.dados
                }, 500)
              } else if (response.data.status === 'mensagem') {
                this.aguardarConsulta = false
                this.$swal({
                  icon: 'warning',
                  text: response.data.messagem,
                })
              } else if (response.data.status === 'error') {
                this.$swal({
                  icon: 'error',
                  text: response.data.messagem,
                })
              }
            }).catch(error => {
              this.aguardarConsulta = true
              this.heightListControl = 0
              this.$swal({
                icon: 'error',
                text: 'Produto não Existe ou Código digitado incorretamente! \n' + error,
              })
            })
          } else {
            await produtoRep.getContendo(this.loja, this.paramconsulta).then(response => {
              if (response.data.status === 'sucesso') {
                setTimeout(() => {
                  this.aguardarConsulta = true
                  this.lista = response.data.dados
                }, 500)
              } else if (response.data.status === 'mensagem') {
                this.aguardarConsulta = false
                this.$swal({
                  icon: 'warning',
                  text: response.data.messagem,
                })
              } else if (response.data.status === 'error') {
                this.$swal({
                  icon: 'error',
                  text: response.data.messagem,
                })
              }
            }).catch(error => {
              this.aguardarConsulta = true
              this.heightListControl = 0
              this.$swal({
                icon: 'error',
                text: 'Produto não Existe ou Código digitado incorretamente! \n' + error,
              })
            })
          }
        } else {
          this.aguardarConsulta = true
        }
      },
      onAddItem (item) {
        if (this.tipoorcamento !== '') {
          if (this.tipoorcamento === 'A') {
            this.itemSelecionado = item
            if (item.estoque <= 0 && !this.preorcamento) {
              this.tipoAlerta = 'warning'
              this.mensagem = 'Estoque Zerado!'
              this.dialogMensagem = true
            } else if (this.codigopreco === 0 && item.vlUnitario === 0) {
              this.tipoAlerta = 'warning'
              this.mensagem = 'Tabela de Preço 1, Contém valor Unitário Zerado'
              this.dialogMensagem = true
            } else if (this.codigopreco === 1 && item.vlUnitario2 === 0) {
              this.tipoAlerta = 'warning'
              this.mensagem = 'Tabela de Preço 2, Contém valor Unitário Zerado'
              this.dialogMensagem = true
            } else if (this.codigopreco === 3 && item.vlUnitario3 === 0) {
              this.tipoAlerta = 'warning'
              this.mensagem = 'Tabela de Preço 3, Contém valor Unitário Zerado'
              this.dialogMensagem = true
            } else if (this.prazo === 0) {
              this.tipoAlerta = 'warning'
              this.mensagem = 'Selecione o Prazo de Pagamento!'
              this.dialogMensagem = true
            } else {
              this.dialogAddProduto = true
            }
          } else {
            this.itemSelecionado = item
            if (item.fisico <= 0 && !this.preorcamento) {
              this.tipoAlerta = 'warning'
              this.mensagem = 'Estoque Zerado!'
              this.dialogMensagem = true
            } else if (this.codigopreco === 0 && item.vlUnitario === 0) {
              this.tipoAlerta = 'warning'
              this.mensagem = 'Tabela de Preço 1, Contém valor Unitário Zerado'
              this.dialogMensagem = true
            } else if (this.codigopreco === 1 && item.vlUnitario2 === 0) {
              this.tipoAlerta = 'warning'
              this.mensagem = 'Tabela de Preço 2, Contém valor Unitário Zerado'
              this.dialogMensagem = true
            } else if (this.codigopreco === 3 && item.vlUnitario3 === 0) {
              this.tipoAlerta = 'warning'
              this.mensagem = 'Tabela de Preço 3, Contém valor Unitário Zerado'
              this.dialogMensagem = true
            } else if (this.prazo === 0) {
              this.tipoAlerta = 'warning'
              this.mensagem = 'Selecione o Prazo de Pagamento!'
              this.dialogMensagem = true
            } else {
              this.dialogAddProduto = true
            }
          }
        } else {
          this.tipoAlerta = 'warning'
          this.mensagem = 'Selecione o Tipo de Pedido (A ou X)!'
          this.dialogMensagem = true
        }
      },
      formatPrice (value) {
        var val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
      onAddProduto (item) {
        this.dialogAddProduto = false
        this.$emit('onAddCardProduto', item)
      },
      onAddSair () {
        this.dialogAddProduto = false
      },
    },
  }
</script>
