import Repository from './repository'

const resource = '/prefduplicata'

export default {

  getId (id) {
    return Repository.get(`${resource}/${id}`)
  },
  async getList (data) {
    return await Repository.get(`${resource}/getlist/${data.flAvista}/${data.flCartao}/${data.cdPrazo}/${data.tipoOrc}`)
  },
  Update (data) {
    return Repository.post(`${resource}/editar/`, data)
  },
  Delete (data) {
    return Repository.post(`${resource}/excluir/`, data)
  },
  Save (data) {
    return Repository.post(`${resource}/save/`, data)
  },
}
